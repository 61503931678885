.rdw-option-wrapper{
    border: none !important;
    padding: 0 !important;
}

.public-DraftStyleDefault-block{
    margin:0 !important;
}

.noti_date_picker{
    z-index: 100  !important;
}


.MuiFormControl-root{
    border: none !important;
    outline: none !important;

}

.MuiInputBase-root{
    border: none !important;
    outline: none !important;

}
.MuiInputBase-root:focus{
    border: none !important;
    outline: none !important;

}
.MuiInputBase-root:hover{
    border: none !important;
    outline: none !important;

}


.MuiIconButton-root{
    border: none !important;
    outline: none !important;

}