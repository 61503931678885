
@media only screen and (min-width: 750px) and (max-width: 850px) {
    .course_model{
        padding:20px !important;
        height: 310px !important;
        
    }
    
    .course_left{
        width: 30% !important;
    }
    .course_thumnail {
        height: 100px  !important;
    }
    .course_left_container{
        margin-top: 50px !important;
    }
    .course_left_title{
        font-size: 12px !important;
    }

    .course_center_error_text{
        margin-top: 0px !important;
    }

    .course_center_tilte{
        margin-top: 0px !important;

    }


    .course_center{
        width: 55% !important;

    }

    .course_center_sub_tilte{
        margin-top: 0px !important;
    }

    .course_center_form{
        margin-top: 0px !important;
    }

    .course_center_form_input{
        margin: 5px !important;
    }

    .course_center_form_button{
        margin-top: 20px !important;
    }

    .course_button_left{
        width: 110px !important;
        font-size: 14px !important;
        margin-right: 20px !important;
    }

    .course_button_right{
        width: 110px !important;
        font-size: 14px !important;
        margin-right: 0;
    }

    .course_button_img{
        height: 35px !important;
    }


    
    .course_right{
        width: 15% !important;

    }

    .cirular_progress_container{
        width: 40px !important;
        margin: 20px auto 0px !important;
    }

    .cirular_progress{
        width: 40px !important;
        height: 40px !important;

    }
}

@media only screen and (min-width: 850px)  and (max-width: 1150px){
    .course_model{
        padding:30px !important;
        height: 400px !important;
        
    }
    
    .course_left{
        width: 30% !important;
    }
    .course_thumnail {
        height: 150px  !important;
    }
    .course_left_container{
        margin-top: 60px !important;
    }
    .course_left_title{
        font-size: 12px !important;
    }



    .course_center{
        margin-top: 20px !important;
        width: 50% !important;

    }

    .course_center_sub_tilte{
        margin-top: 0px !important;
    }

    .course_center_form{
        margin-top: 20px !important;
    }

    .course_center_form_input{
        margin: 5px !important;
    }

    .course_center_form_button{
        margin-top: 30px !important;
    }

    .course_button_left{
        width: 120px !important;
        font-size: 14px !important;
        margin-right: 20px !important;
    }

    .course_button_right{
        width: 120px !important;
        font-size: 14px !important;
        margin-right: 0;
    }

    .course_button_img{
        height: 40px !important;
    }

    
    .course_right{
        width: 20% !important;

    }
}

 

@media only screen and (min-width: 620px) and (max-width: 750px) {
    .course_model{
        padding:20px !important;
        height: 310px !important;
        width: 95vw !important;
        
    }
    
    .course_left{
        width: 30% !important;
    }
    .course_thumnail {
        height: 100px  !important;
    }
    .course_left_container{
        margin-top: 50px !important;
    }
    .course_left_title{
        font-size: 12px !important;
    }


    .course_center{
        width: 55% !important;

    }

    .course_center_tilte{
        margin-top: 0px !important;

    }

    .course_center_sub_tilte{
        margin-top: 0px !important;
    }

    .course_center_form{
        margin-top: 0px !important;
    }

    .course_center_form_input{
        margin: 5px !important;
    }

    .course_center_form_button{
        margin-top: 20px !important;
    }

    .course_button_left{
        width: 110px !important;
        font-size: 14px !important;
        margin-right: 20px !important;
    }

    .course_button_right{
        width: 110px !important;
        font-size: 14px !important;
        margin-right: 0;
    }

    .course_button_img{
        height: 35px !important;
    }


    
    .course_right{
        width: 15% !important;

    }

    .cirular_progress_container{
        width: 40px !important;
        margin: 20px auto 0px !important;
    }

    .cirular_progress{
        width: 40px !important;
        height: 40px !important;

    }
}

@media only screen and (max-width: 620px) {
    .course_model{
        padding:20px !important;
        height: 310px !important;
        width: 95vw !important;
        
    }
    
    .course_left{
        width: 30% !important;
    }
    .course_thumnail {
        height: 100px  !important;
    }
    .course_left_container{
        margin-top: 50px !important;
    }
    .course_left_title{
        font-size: 12px !important;
    }

    .course_center_error_text{
        margin-top: 0px !important;
    }



    .course_center{
        width: 55% !important;

    }

    .course_center_tilte{
        margin-top: 0px !important;

    }
    .course_center_main_tilte{
        margin-top: 0px !important;

    }


    .course_center_sub_tilte{
        margin-top: 0px !important;
    }

    .course_center_form{
        margin-top: 0px !important;
    }

    .course_center_form_input{
        margin: 5px !important;
    }

    .course_center_form_button{
        margin-top: 20px !important;
    }

    .course_button_left{
        width: 110px !important;
        font-size: 14px !important;
        margin-right: 20px !important;
    }

    .course_button_right{
        width: 110px !important;
        font-size: 14px !important;
        margin-right: 0;
    }

    .course_button_img{
        height: 35px !important;
    }


    
    .course_right{
        width: 15% !important;

    }

    .cirular_progress_container{
        width: 40px !important;
        margin: 20px auto 0px !important;
    }

    .cirular_progress{
        width: 40px !important;
        height: 40px !important;

    }
}
